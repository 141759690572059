define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header", ["exports", "discourse/components/d-button", "discourse/components/header/user-dropdown", "discourse/components/header/user-dropdown/notifications", "discourse/helpers/concat-class", "discourse-i18n", "discourse/plugins/discourse-global-communities/discourse/components/agc/header/explore-dropdown", "discourse/plugins/discourse-global-communities/discourse/components/agc/header/nav-item", "discourse/plugins/discourse-global-communities/discourse/components/agc/header/standalone-notifications-wrapper", "discourse/plugins/discourse-global-communities/discourse/components/agc/header/standalone-user-menu-wrapper", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dButton, _userDropdown, _notifications, _concatClass, _discourseI18n, _exploreDropdown, _navItem, _standaloneNotificationsWrapper, _standaloneUserMenuWrapper, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.customizeHeader = customizeHeader;
  function customizeHeader(api, headerIcons) {
    const siteSettings = api.container.lookup("service:site-settings");
    if (siteSettings.glimmer_header_mode === "disabled") {
      return;
    }
    hideDefaultIcons(api);
    addCustomIcons(api, headerIcons);
    patchSearchMenuToggler(api);
    addExploreDropdown(api);
    addHeaderBackButton(api);
    splitNotificationMenu(api);
  }
  function hideDefaultIcons(api) {
    api.headerIcons.delete("chat");
  }
  function addCustomIcons(api, headerIcons) {
    const site = api.container.lookup("service:site");
    const currentUser = api.getCurrentUser();
    headerIcons.forEach(navItem => {
      const agcAnalyticsRef = navItem?.className ? `nav_${navItem.className}` : null;
      if (!navItem) {
        return;
      }
      const position = {
        before: [],
        after: [],
        ...navItem.position
      };
      if (typeof position.before === "string") {
        position.before = [navItem.position.before];
      }
      position.before.push("standalone-notifications", "standalone-user-menu", "user-menu");
      if (typeof position.after === "string") {
        position.after = [navItem.position.after];
      }
      position.after.push("agc-ios-back-btn");
      switch (navItem?.type) {
        case "link":
          api.headerIcons.add(navItem.id, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
          /*
            
                      <AgcHeaderNavItem
                        class={{navItem.className}}
                        @href={{navItem.href}}
                        @icon={{navItem.icon}}
                        @label={{navItem.label}}
                        @hideLabel={{site.mobileView}}
                        @agcAnalyticsRef={{agcAnalyticsRef}}
                        @onClick={{navItem.action}}
                        @showIndicator={{navItem.showIndicator}}
                      />
                    
          */
          {
            "id": "7VfrNexE",
            "block": "[[[1,\"\\n            \"],[8,[32,0],[[16,0,[32,1,[\"className\"]]]],[[\"@href\",\"@icon\",\"@label\",\"@hideLabel\",\"@agcAnalyticsRef\",\"@onClick\",\"@showIndicator\"],[[32,1,[\"href\"]],[32,1,[\"icon\"]],[32,1,[\"label\"]],[32,2,[\"mobileView\"]],[32,3],[32,1,[\"action\"]],[32,1,[\"showIndicator\"]]]],null],[1,\"\\n          \"]],[],false,[]]",
            "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
            "scope": () => [_navItem.default, navItem, site, agcAnalyticsRef],
            "isStrictMode": true
          }), (0, _templateOnly.default)(undefined, undefined)), position);
          break;
        case "chat":
          const hideUnreadIndicator = currentUser.isInDoNotDisturb();
          // we need to require the component here because the chat plugin can be disabled which would cause the
          // application to crash with a static import
          const ChatHeaderIconUnreadIndicator = require("discourse/plugins/chat/discourse/components/chat/header/icon/unread-indicator").default;
          api.headerIcons.add(navItem.id, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
          /*
            
                      <AgcHeaderNavItem
                        class={{concatClass
                          navItem.className
                          "header-dropdown-toggle"
                          "chat-header-icon"
                        }}
                        @href={{navItem.href}}
                        @icon={{navItem.icon}}
                        @label={{navItem.label}}
                        @hideLabel={{site.mobileView}}
                        @agcAnalyticsRef={{agcAnalyticsRef}}
                      >
                        {{#unless hideUnreadIndicator}}
                          <span><ChatHeaderIconUnreadIndicator /></span>
                        {{/unless}}
                      </AgcHeaderNavItem>
                    
          */
          {
            "id": "32MBy++j",
            "block": "[[[1,\"\\n            \"],[8,[32,0],[[16,0,[28,[32,1],[[32,2,[\"className\"]],\"header-dropdown-toggle\",\"chat-header-icon\"],null]]],[[\"@href\",\"@icon\",\"@label\",\"@hideLabel\",\"@agcAnalyticsRef\"],[[32,2,[\"href\"]],[32,2,[\"icon\"]],[32,2,[\"label\"]],[32,3,[\"mobileView\"]],[32,4]]],[[\"default\"],[[[[1,\"\\n\"],[41,[51,[32,5]],[[[1,\"                \"],[10,1],[12],[8,[32,6],null,null,null],[13],[1,\"\\n\"]],[]],null],[1,\"            \"]],[]]]]],[1,\"\\n          \"]],[],false,[\"unless\"]]",
            "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
            "scope": () => [_navItem.default, _concatClass.default, navItem, site, agcAnalyticsRef, hideUnreadIndicator, ChatHeaderIconUnreadIndicator],
            "isStrictMode": true
          }), (0, _templateOnly.default)(undefined, undefined)), position);
          break;
        default:
      }
    });
  }
  function patchSearchMenuToggler(api) {
    api.modifyClass("component:header", {
      pluginId: "agc-navs-customize-header",
      toggleSearchMenu() {
        // prevents the default search menu from being displayed
        this.search.visible = false;
        // in case the user presses the keyboard shortcut we will focus the custom field inserted in the header
        this.search.highlightTerm = "";
        this.search.inTopicContext = false;
        document.getElementById("search-term")?.focus();
      }
    });
  }
  function addExploreDropdown(api) {
    const currentUser = api.getCurrentUser();
    const site = api.container.lookup("service:site");
    const siteSettings = api.container.lookup("service:site-settings");
    if (!currentUser || !siteSettings.show_explore_dropdown || site.mobileView) {
      return;
    }
    api.renderAfterWrapperOutlet("home-logo-wrapper", _exploreDropdown.default);
  }
  function splitNotificationMenu(api) {
    const agcHeader = api.container.lookup("service:agc-header");
    if (!agcHeader.splitUserMenuAndNotificationsEnabled) {
      return;
    }
    addStandaloneUserMenu(api, agcHeader);
    addStandaloneNotificationsMenu(api, agcHeader);
  }
  function addHeaderBackButton(api) {
    const currentUser = api.getCurrentUser();
    const caps = api.container.lookup("service:capabilities");
    const appEvents = api.container.lookup("service:app-events");
    // only show the back button on iOS webviews
    if (!currentUser || !caps.isIOS || !caps.isAppWebview) {
      return;
    }
    const goBack = () => {
      window.history.back();
    };
    api.headerIcons.add("agc-ios-back-btn", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <div class="header-back-button">
            <DButton
              class="btn-flat"
              @icon="chevron_left-squid"
              @action={{goBack}}
            />
          </div>
        
    */
    {
      "id": "nG9lsSQi",
      "block": "[[[1,\"\\n      \"],[10,0],[14,0,\"header-back-button\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"btn-flat\"]],[[\"@icon\",\"@action\"],[\"chevron_left-squid\",[32,1]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
      "scope": () => [_dButton.default, goBack],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)), {
      before: "hamburger"
    });
    appEvents.on("page:changed", route => {
      if (route.url === "/") {
        document.body.classList.remove("show-header-back-button");
      } else {
        document.body.classList.add("show-header-back-button");
      }
    });
  }
  function addStandaloneUserMenu(api, agcHeader) {
    const currentUser = api.getCurrentUser();
    // standalone user menu
    api.headerIcons.delete("user-menu");
    api.headerIcons.add("standalone-user-menu", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          {{#if currentUser}}
            <UserDropdown
              @active={{agcHeader.standaloneUserMenuVisible}}
              @toggleUserMenu={{agcHeader.toggleStandaloneUserMenu}}
            />
          {{/if}}
        
    */
    {
      "id": "LqXleucE",
      "block": "[[[1,\"\\n\"],[41,[32,0],[[[1,\"        \"],[8,[32,1],null,[[\"@active\",\"@toggleUserMenu\"],[[32,2,[\"standaloneUserMenuVisible\"]],[32,2,[\"toggleStandaloneUserMenu\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
      "scope": () => [currentUser, _userDropdown.default, agcHeader],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)),
    // user-menu is removed but we'll keep the reference in the DAG in case other unknown buttons are added also using
    // as a reference point
    {
      before: "user-menu",
      after: "agc-ios-back-btn"
    });
    api.renderInOutlet("after-header-panel", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <StandaloneUserMenuWrapper
          @toggleUserMenu={{agcHeader.toggleStandaloneUserMenu}}
        />
      
    */
    {
      "id": "ef9LrhjY",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@toggleUserMenu\"],[[32,1,[\"toggleStandaloneUserMenu\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
      "scope": () => [_standaloneUserMenuWrapper.default, agcHeader],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  }
  function addStandaloneNotificationsMenu(api, agcHeader) {
    const currentUser = api.getCurrentUser();
    const site = api.container.lookup("service:site");
    if (!currentUser || site.mobileView) {
      return;
    }
    const notificationsLabel = (0, _discourseI18n.i18n)("user.notifications");
    // standalone notifications panel
    api.headerIcons.add("standalone-notifications", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
          <AgcHeaderNavItem
            class="notifications header-dropdown-toggle agc-user-notifications-menu"
            aria-haspopup={{true}}
            aria-expanded={{agcHeader.standaloneNotificationsVisible}}
            data-auto-route={{true}}
            @icon="bell-squid"
            @label={{notificationsLabel}}
            @onClick={{agcHeader.toggleStandaloneNotifications}}
          >
            <Notifications @active={{@active}} />
          </AgcHeaderNavItem>
        
    */
    {
      "id": "us5vfg7V",
      "block": "[[[1,\"\\n      \"],[8,[32,0],[[24,0,\"notifications header-dropdown-toggle agc-user-notifications-menu\"],[16,\"aria-haspopup\",true],[16,\"aria-expanded\",[32,1,[\"standaloneNotificationsVisible\"]]],[16,\"data-auto-route\",true]],[[\"@icon\",\"@label\",\"@onClick\"],[\"bell-squid\",[32,2],[32,1,[\"toggleStandaloneNotifications\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,3],null,[[\"@active\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[\"@active\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
      "scope": () => [_navItem.default, agcHeader, notificationsLabel, _notifications.default],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)), {
      before: "standalone-user-menu",
      after: "agc-ios-back-btn"
    });
    api.renderInOutlet("after-header-panel", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <StandaloneNotificationsWrapper
          @toggleUserMenu={{agcHeader.toggleStandaloneNotifications}}
        />
      
    */
    {
      "id": "jsUtdHNf",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@toggleUserMenu\"],[[32,1,[\"toggleStandaloneNotifications\"]]]],null],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-navs/navs/customize-header.js",
      "scope": () => [_standaloneNotificationsWrapper.default, agcHeader],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  }
});