define("discourse/plugins/discourse-global-communities/discourse/feature-flags/agc-post-intent", ["exports", "@ember/object", "discourse/models/composer", "discourse-i18n"], function (_exports, _object, _composer, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcPostIntentFeatureFlag {
    static enabledKey = "agc_post_intent_enabled";
    static className = "agc-post-intent-enabled";
    static afterEnabled(api) {
      // we need to serialize the post intent on create and update
      _composer.default.serializeOnCreate("agc_post_intent", "agcPostIntent");
      _composer.default.serializeOnUpdate("agc_post_intent", "agcPostIntent");
      const currentUser = api.getCurrentUser();
      const composerService = api.container.lookup("service:composer");
      const postIntentService = api.container.lookup("service:agc-post-intent");
      const siteSettings = api.container.lookup("service:site-settings");
      api.onAppEvent("composer-service:last-validated-at-updated", _ref => {
        let {
          model
        } = _ref;
        // Here we only need to check if post intent is required and model doesn't have it, because
        // we won't render the input popup if post intent isn't present in composer. No harm, no foul.
        if (siteSettings.agc_post_intent_required && !model?.agcPostIntent) {
          postIntentService.postIntentValidation = _object.default.create({
            failed: true,
            reason: (0, _discourseI18n.i18n)("agc.composer.error.post_intent"),
            lastShownAt: composerService.lastValidatedAt
          });
        }
      });
      api.onAppEvent("composer-service:last-validated-at-cleared", () => {
        postIntentService.postIntentValidation = null;
      });
      api.registerValueTransformer("composer-service-cannot-submit-post", _ref2 => {
        let {
          value,
          context
        } = _ref2;
        // If we are already blocked on something, then simply return true -- no need for additional checks.
        // Remember this is CANT submit post.
        if (value) {
          return value;
        }

        // Return false if user doesn't have access to post intent, OR it's not required.
        if (!currentUser.agcFeatureFlags.agc_post_intent_enabled || !siteSettings.agc_post_intent_required) {
          return false;
        }

        // If we aren't creating a new topic or editing first post
        if (!context.model.editingFirstPost && !context.model.creatingTopic) {
          return false;
        }

        // Return true if agcPostIntent is undefined. (true === can't post)
        return !context.model.agcPostIntent && siteSettings.agc_post_intent_required;
      });
    }
  }
  _exports.default = AgcPostIntentFeatureFlag;
});